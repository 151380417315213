/* Importing Poppins Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
h1,
h2,
h3,
h4,
a,
li {
  letter-spacing: 0.05rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="text"]:focus {
  box-shadow: none;
}
.modal-bg,
.modal-bg > p {
  background-color: white;
  font-size: 14px;
}

/* Overriding default style of legend in earnings growth chart  */
li.recharts-legend-item.legend-item-0 {
  margin-right: 20px !important;
}

/* Overriding default style of scrollbar  */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #262626;
  border-radius: 20px;
  border: 3px solid var(--primary);
}

@layer components {
  .HollowedNumber {
    -webkit-text-stroke: 1.5px black;
  }
  .HollowedNumberLight {
    -webkit-text-stroke: 1px black;
  }

  .Card {
    background: #fcfcfc;
    /* BA Glow Effect */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 5px -1px 10px #f3f3f3;
    border-radius: 10px;
  }

  .Input {
    box-shadow: inset -4px -4px 4px #ffffff,
      inset 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    outline: none;
  }

  .Input-2 {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 5px -1px 10px #f3f3f3;
  }

  .btn-dashed-under:hover::before {
    content: "";
    @apply absolute bottom-[5px] left-0 right-0 z-[-1] h-full max-h-[7px] w-full bg-[#16DB93] lg:bottom-[10px];
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

@layer base {
  /* Input placeholder text bold by default  */
  input::-webkit-input-placeholder {
    @apply font-bold;
  }
  /* Remove browser default behaviour for autofill input */
  input:-webkit-autofill {
    -webkit-background-clip: text;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }

  input[type="checkbox"]:focus {
    outline: 0;
  }

  input[type="number"]:focus {
    outline: none;
  }

  /* Removes the cancel button in search field */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  /* Letter spacing of 0.05em for h1, h2, h3 */
  h1,
  h2,
  h3,
  p,
  button,
  label,
  input::-webkit-input-placeholder {
    @apply tracking-wider;
  }
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    }

    .text-shadow-none {
      text-shadow: none;
    }
  }
}
